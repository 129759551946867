<template>
  <div class="promo">
    <b-container fluid>

      <!-- Promo update -->
      <a v-if="promoPeriod"
        href="https://biogreenscience.com/index.php?page=m4104j4110h4200c4086x4134v4152u3774h4176o4188t4170m4158v4170w3774f4176b4086y4098w4146g4086r4122q4110s3774p4194h4206g4176l4110k4188e3774m4128s4110o4086a4152f4200x4128"
        target="_blank">
        <b-row class="for_desktop promo1">
          <img src="../../assets/images/promo/banner_suph-03_h.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
        </b-row>
        <b-row class="for_tablet promo1">
          <img src="../../assets/images/promo/banner_suph-03_h.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
        </b-row>
        <b-row class="for_mobile promo1">
          <img src="../../assets/images/promo/banner_suph-03_1x1.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_potrait.jpeg" width="100%"> -->
        </b-row>
      </a>

      <!-- Tour update -->
      <a href="https://biogreenscience.com/index.php?page=z4104t4110o4200b4086h4134o4152s3774q4176y4188w4170t4158y4170u3774k4086v4206c4194a4194g4134g4110s3774t3804z3792r3804x3816"
        target="_blank">
        <b-row class="for_desktop promo1">
          <img src="../../assets/images/promo/aussie-trip-h-ext.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
        </b-row>
        <b-row class="for_tablet promo1">
          <img src="../../assets/images/promo/aussie-trip-h-ext.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
        </b-row>
        <b-row class="for_mobile promo1">
          <img src="../../assets/images/promo/aussie-trip-1x1-ext.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_potrait.jpeg" width="100%"> -->
        </b-row>
      </a>

      <!-- Tour2 update -->
      <a v-if="promoPeriod" href="https://biogreenscience.com/index.php?page=g4104w4110z4200a4086j4134a4152q3774s4200y4128s4086a4134z3774r4122z4110g4200e4086u4218i4086r4230&lang=id"
        target="_blank">
        <b-row class="for_desktop promo1">
          <img src="../../assets/images/promo/banner_thai_update01-h.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
        </b-row>
        <b-row class="for_tablet promo1">
          <img src="../../assets/images/promo/banner_thai_update01-h.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_lanscape.jpeg" width="100%"> -->
        </b-row>
        <b-row class="for_mobile promo1">
          <img src="../../assets/images/promo/banner_thai_update01-s.jpg" width="100%">
          <!-- <img src="../../assets/images/join_platinum_potrait.jpeg" width="100%"> -->
        </b-row>
      </a>

      <!-- Countdown promo -->
      <b-row>
        <b-col sm="12" class="countDown">
          <div class="for_desktop">
            <b-row>
              <b-col sm="4" class="countDownItem"></b-col>
              <b-col sm="1" class="countDownItem"><b-button variant="primary">{{ hari }}</b-button>
                <p>{{ data.hari }}</p>
              </b-col>
              <b-col sm="1" class="countDownItem"><b-button variant="primary">{{ jam }}</b-button>
                <p>{{ data.jam }}</p>
              </b-col>
              <b-col sm="1" class="countDownItem"><b-button variant="primary">{{ menit }}</b-button>
                <p>{{ data.menit }}</p>
              </b-col>
              <b-col sm="1" class="countDownItem"><b-button variant="primary">{{ detik }}</b-button>
                <p>{{ data.detik }}</p>
              </b-col>
              <b-col sm="4" class="countDownItem"></b-col>
            </b-row>
          </div>
          <div class="for_tablet">
            <b-row>
              <b-col sm="2" class="countDownItem"></b-col>
              <b-col sm="2" class="countDownItem"><b-button variant="primary">{{ hari }}</b-button>
                <p>{{ data.hari }}</p>
              </b-col>
              <b-col sm="2" class="countDownItem"><b-button variant="primary">{{ jam }}</b-button>
                <p>{{ data.jam }}</p>
              </b-col>
              <b-col sm="2" class="countDownItem"><b-button variant="primary">{{ menit }}</b-button>
                <p>{{ data.menit }}</p>
              </b-col>
              <b-col sm="2" class="countDownItem"><b-button variant="primary">{{ detik }}</b-button>
                <p>{{ data.detik }}</p>
              </b-col>
              <b-col sm="2" class="countDownItem"></b-col>
            </b-row>
          </div>
          <div class="for_mobile">
            <b-row>
              <b-col cols="3" class="countDownItem"><b-button variant="primary">{{ hari }}</b-button>
                <p>{{ data.hari }}</p>
              </b-col>
              <b-col cols="3" class="countDownItem"><b-button variant="primary">{{ jam }}</b-button>
                <p>{{ data.jam }}</p>
              </b-col>
              <b-col cols="3" class="countDownItem"><b-button variant="primary">{{ menit }}</b-button>
                <p>{{ data.menit }}</p>
              </b-col>
              <b-col cols="3" class="countDownItem"><b-button variant="primary">{{ detik }}</b-button>
                <p>{{ data.detik }}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      endDate: new Date('2024-01-31'),
      bahasa: 'id',
      data: {
        promoTitle: null,
        promo1Nilai: null,
        promo3Desc: null,
        promo3Desc1: null,
        promo3Desc2: null,
        promo3Desc3: null,
        hari: null,
        jam: null,
        menit: null,
        detik: null
      },
      hari: 0,
      jam: 0,
      menit: 0,
      detik: 0,
      bulanSekarang: 0,
      tahunSekarang: 0
    }
  },
  mounted() {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    this.bulanSekarang = monthNames[d.getMonth()]
    this.tahunSekarang = new Date().getFullYear()

    this.bahasa = localStorage.getItem('bahasa')


    axios.get(`../../lang_${this.bahasa}.json`).then(response => {
      // console.log(response.data.data[9].promo[0].promoTitle)
      this.data.hari = response.data.data[10].timmer[0].timmerHari
      this.data.jam = response.data.data[10].timmer[0].timmerJam
      this.data.menit = response.data.data[10].timmer[0].timmerMenit
      this.data.detik = response.data.data[10].timmer[0].timmerDetik

    })

    axios.get(`../../lang_${this.bahasa}.json`).then(response => {
      console.log(response.data.data[9].promo[0].promoTitle)
      this.data.promoTitle = response.data.data[9].promo[0].promoTitle
      this.data.promo1Nilai = response.data.data[9].promo[0].promo1Nilai
      this.data.promo3Desc = response.data.data[9].promo[0].promo3Desc

      this.data.promo3Desc1 = response.data.data[9].promo[0].promo3Desc1
      this.data.promo3Desc2 = response.data.data[9].promo[0].promo3Desc2
      this.data.promo3Desc3 = response.data.data[9].promo[0].promo3Desc3
    })

  },

  created() {
    this.interval = setInterval(() => this.getTime(), 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getTime() {
      /* const countDownDate = new Date(this.bulanSekarang + "1, " + this.tahunSekarang).getTime(); */
      const countDownDate = new Date('September 30, 2024').getTime();
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const periode = 0;
      this.hari = Math.floor(distance / (1000 * 60 * 60 * 24)) + 1 - periode;
      this.jam = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.menit = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.detik = Math.floor((distance % (1000 * 60)) / 1000);

    },
  },
  computed: {
    promoPeriod() {
      const currentDate = new Date();
      return currentDate < this.endDate;
    },
  },
}
</script>

<style scoped>
.countDownItem button {
  background: linear-gradient(15deg, rgb(55 20 76) 67%, rgb(166 3 130) 98%) !important;
  padding: 30px 10px !important;
  font-size: 40px !important;
  border-color: transparent !important;
}

.countDownItem p {
  color: #37144c !important;
}

@media only screen and (max-width: 639px) {
  .countDownItem button {
    padding: 10px 10px 0 10px !important;
    font-size: 18px !important;
    width: 45px !important;
  }
}
</style>

